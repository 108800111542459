body {
    background-color: snow;
    overflow-x: scroll;
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }

  .thePuppyButton{
      position: fixed;
      top:50%;
      right:50%
  }